import React from 'react';
import './App.scss'; 

const App = () => ( 
  <div className="App"> 
    <a href="https://open.spotify.com/album/5iiKMkLQJGn7DpgGWRurYc?si=HcpKO_53QumlJk5Spx8Yrw" className="square">Roteiro Pra Aïnouz, Vol. 3</a>
    <a href="https://open.spotify.com/album/7b6D3zdYbbxSjfYJ7pKge9?si=1YA6boXORFCFcpHhEZz0kQ" className="square">2ª Via</a>
    <a href="https://open.spotify.com/album/25fli8PlxpP10WpirvDpMT?si=JUmKbRl7QU62_3bjIIs60g" className="square">Pearl</a>
    <a href="https://open.spotify.com/album/1TSZDcvlPtAnekTaItI3qO?si=fvd_Zyx9RtCg3G6rt4zPQA" className="square">A night at the opera</a>
    <a href="https://open.spotify.com/album/33pt9HBdGlAbRGBHQgsZsU?si=OiMDnNMtQ0Gj6pavDmkPLg" className="square">Evolve</a>
    <a href="https://open.spotify.com/album/6bcl0ZcPJWJtyuRsOyhvfv?si=Qq8rJQ9nSHGt5sbBbra2dA" className="square">Hozier</a>
    <a href="https://open.spotify.com/album/5cUY5chmS86cdonhoFdn8h?si=UE557EiYSX2lh2loW0zEPw" className="square">AmarElo</a>
    <a href="https://open.spotify.com/album/4mgTW0NU3fjNBxHdKOJoRr?si=3FbHE9GYQTOT68APl1SK_Q" className="square">O menino que queria ser deus</a>
    <a href="https://open.spotify.com/album/1XkGORuUX2QGOEIL4EbJKm?si=jpEwcxIKQVm9yC0lO5G06A" className="square">Favorite worst nightmare</a>
  </div> 
);
    
    
export default App;